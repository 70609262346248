import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import HyvorTalk from "hyvor-talk-react"
import { navigate } from "gatsby"
import ReadingIcon from "../../static/icons/reading-large.svg"
import ActivityIcon from "../../static/icons/activity-large.svg"
import VideoIcon from "../../static/icons/video-large.svg"
import AssessmentIcon from "../../static/icons/assessment-large.svg"
import CheckpointIcon from "../../static/icons/checkpoint-large.svg"
import LessonPlanIcon from "../../static/icons/lessonplan.svg" // Lesson Plan icon by Erik Arndt from the Noun Project

// function Aside(props) {
//   if (props.frontmatter.media) {
//     return (
//       <aside>
//         <section className="lesson-media">
//           <h4>Media</h4>
//           <Media items={props.frontmatter.media} />
//         </section>
//         <section>
//           <form name="Lesson Feedback" method="POST" data-netlify="true">
//             <input type="hidden" name="form-name" value="Lesson Feedback" />
//             <input
//               type="hidden"
//               name="lesson"
//               value={props.frontmatter.title}
//             />
//             <label>
//               <h4>Feedback</h4>
//               <div>How can we improve this lesson?</div>
//               <textarea name="feedback"></textarea>
//             </label>
//             <p>
//               <button type="submit">Send Feedback</button>
//             </p>
//           </form>
//         </section>
//       </aside>
//     )
//   } else {
//     return ""
//   }
// }

function Objective(props) {
  if (props.objective) {
    return (
      <blockquote>
        As a result of completing the {props.title} lesson, students will be
        able to {props.objective}.
      </blockquote>
    )
  } else {
    return ""
  }
}

function EditOnGithub(props) {
  let showNavigation = process.env.SHOW_NAVIGATION === "true";
  let showInProd = props.prod === "true";
  if (props.path && (showNavigation || showInProd)) {
    let path = props.path;
    path = path.replace(/\/index.html/, ".md");
    let url = "https://github.com/KenzieAcademy/se-9-month-curriculum/blob/develop/lessons/9-month-curriculum" + path;
    let divStyle = {
      margin: "0.5em 0",
      fontSize: "small"
    }
    let linkStyle = {};
    let endingText = ""
    if (showInProd && !showNavigation) {
      divStyle.color = "lightgray";
      linkStyle.color = "lightgray";
      endingText = "(Instructors Only)"
    }
    return (
      <div style={divStyle}>See a typo? <a href={url} style={linkStyle} target="_blank" rel="noreferrer">Edit this page on Github</a>&nbsp;{endingText}</div>
    )
  } else {
    return "";
  }
}

function Navigation(props) {
  let showNavigation = process.env.SHOW_NAVIGATION
  if (
    showNavigation === "true" &&
    !(
      props.path === "curriculum" ||
      props.path === "/curriculum" ||
      props.path === "/curriculum/"
    )
  ) {
    return (
      <nav style={{ display: "flex" }}>
        <NavButton to={props.prev} name="Prev" blue />
        <NavButton to="/curriculum/index.html" name="Home" />
        <StatusMessage status={props.status} style={{ flexGrow: 1 }} />
        <NavButton to={props.next} name="Next" blue />
      </nav>
    );
  } else {
    return ""
  }
}

function NavButton(props) {
  if (props.to) {
    function handleClick() {
      navigate(props.to)
    }

    return (
      <button
        style={{ height: "2em" }}
        className={`navButton ${props.blue ? "blue" : ""} ${props.left ? "left" : ""
          } ${props.right ? "right" : ""}`}
        onClick={handleClick}
      >
        {props.name}
      </button>
    )
  }

  return ""
}

function EndNav(props) {
  let showNavigation = process.env.SHOW_NAVIGATION
  if (showNavigation !== "true" || (!props.next && !props.prev)) {
    return ""
  }

  return (
    <nav className="clearfix">
      <NavButton to={props.prev} name="Prev" blue left />
      <NavButton to={props.next} name="Next" blue right />
    </nav>
  )
}

function StatusMessage(props) {
  let showTitle = process.env.SHOW_NAVIGATION

  let messageStyle = {
    background: "#D4EBE5",
    textAlign: "center",
    lineHeight: "calc(2em - 4px)",
    margin: "0 1em",
    borderRadius: "1em",
    border: "2px solid black",
    boxSizing: "border-box",
    flexGrow: 1
  }

  let warningStyle = {
    ...messageStyle,
    background: "#FDC54C"
  }

  let errorStyle = {
    ...messageStyle,
    background: "#FC4F4F"
  }

  if (showTitle === "true") {
    if (props.status === "DONE") {
      return <div style={{ flexGrow: 1 }}></div>;
    } else if (props.status === "REVIEW") {
      return (
        <div style={messageStyle}>Page is Ready for Review!</div>
      );
    } else if (props.status === "REMOVED") {
      return (
        <div style={errorStyle}>Page Not In Use!</div>
      );
    }
    else {
      return (
        <div style={warningStyle}>Page Still In Development...</div>
      );
    }
  } else {
    return <div style={{ flexGrow: 1 }}></div>;
  }

}

function Title(props) {
  let showTitle = process.env.SHOW_NAVIGATION
  if (showTitle === "true") {
    return (
      <div style={{
        display: "flex",
        borderBottom: "1px solid hsla(0,0%,0%,0.2)",
        marginBottom: "1.58rem"
      }}>
        <h1 style={{ fontSize: "300%", flexGrow: 1, border: "none", marginBottom: 0, paddingBottom: 0 }}>{props.title}</h1>
        {props.accOnly ? <div style={{ fontSize: "300%", marginTop: "2.4rem" }}>(ACC)</div> : ""}
        <img
          src={props.typeImage}
          alt="Curriculum Type"
          style={{
            height: "96px",
            flexBasis: "96px",
            marginRight: "0.2em",
            marginTop: "2.4rem"
          }}
        />

      </div>
    )
  } else {
    return "";
  }
}

function Comments(props) {
  let showNavigation = process.env.SHOW_NAVIGATION
  if (showNavigation === "true") {
    return <HyvorTalk.Embed websiteId={2480} id={props.path} />
  } else {
    return ""
  }
}

export default function Template({ data, pageContext, location }) {
  let { title, prev, next, pagePath, type, status, accOnly } = pageContext;
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html } = markdownRemark

  const [env, setEnv] = useState()
  let enableSupportHub = location.search.indexOf("supportHub=false") === -1 && process.env.SHOW_HELP === "true";

  useEffect(() => {
    window.addEventListener("message", (event) => {
      console.log("Message Received");
      console.log(event);
      if (event.data.type === "ENV_UPDATE") {
        window.parent.postMessage({
          type: "ENV_UPDATE_CONFIRMATION"
        }, "*");
        setEnv(event.data.env);
        console.log("setting env");
        console.log(event.data.env);
      }
    })
    document.querySelector("iframe#attach")
    console.log("effect");
  }, [])
  useEffect(() => {//workaround for adding Jira Support Widget

    if (enableSupportHub) {


      const script = document.createElement('script');

      script.src = "https://jsd-widget.atlassian.com/assets/embed.js";
      script.id = 'jiraWidget';
      script.setAttribute('async', true);
      script.setAttribute('data-jsd-embedded', '');
      script.setAttribute('data-key', "b5e169aa-2077-4bb9-9753-d0cea6b0f372");
      script.setAttribute('data-base-url', "https://jsd-widget.atlassian.com");

      document.body.appendChild(script);

      script.addEventListener('load', (event) => {
        window.document.dispatchEvent(new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true
        }));
      }
      )
      return () => {
        document.body.removeChild(script);
      }

    }
  }, [enableSupportHub]);

  let typeImage;

  switch (type) {
    case "READING":
      typeImage = ReadingIcon;
      title = `Reading - ${title}`
      break;
    case "CHECKPOINT":
      typeImage = CheckpointIcon;
      title = `Checkpoint - ${title}`
      break;
    case "VIDEO":
      typeImage = VideoIcon;
      title = `Video - ${title}`
      break;
    case "ACTIVITY":
      typeImage = ActivityIcon;
      title = `Activity - ${title}`
      break;
    case "ASSESSMENT":
      typeImage = AssessmentIcon;
      title = `Assessment - ${title}`
      break;
    case "CHALLENGE":
      typeImage = ActivityIcon;
      title = `Challenge - ${title}`
      break;
    case "SUBMODULE_OVERVIEW":
      typeImage = ReadingIcon;
      title = `Submodule Overview - ${title}`
      enableSupportHub = false;
      break;
    case "MODULE_OVERVIEW":
      typeImage = ReadingIcon;
      title = `Module Overview - ${title}`
      enableSupportHub = false;
      break;
    case "LESSON_PLAN":
      typeImage = LessonPlanIcon;
      title = `Lesson Plan - ${title}`
      break;
    case "ANSWER_KEY":
      typeImage = ActivityIcon
      title = `Answer Key - ${title}`
      break;
    default:
      typeImage = ReadingIcon;
      break;
  }

  let emoji = "📒"

  let showOnlyContent = process.env.SHOW_ONLY_CONTENT
  if (
    showOnlyContent === "true" &&
    !(
      pagePath === "/" ||
      pagePath.startsWith("/modules/") ||
      pagePath.startsWith("modules/") ||
      pagePath.startsWith("/course_information/") ||
      pagePath.startsWith("course_information/")
    )
  ) {
    if (typeof window !== 'undefined') {
      window.location = '/404/index.html';
    }
    return null;
  }

  return (
    <div className="lesson-container">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex,nofollow"></meta>
        <title>{title}</title>
        <link
          rel="shortcut icon"
          href={"https://emoji.beeimg.com/" + emoji + "/144/twitter"}
        />
      </Helmet>
      <div
        className="lesson"
        style={{
          maxWidth: "60rem",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "1.5rem 1.125rem",
          position: "relative",
        }}
      >
        <header className="lesson-header">
          <Navigation
            path={pagePath}
            next={next}
            prev={prev}
            status={status}
          />
          <EditOnGithub path={pagePath} />
          <Title title={title} typeImage={typeImage} accOnly={accOnly} />
          <Objective
            title={title}
          />
        </header>
        <main className="lesson-main">
        <div class="supporthub-button-container" style={{ alignItems: 'baseline' }}>
            <a style={{ fontSize: '14px' }} href={pagePath} target='_blank' >Access the Accessible Version</a>

            </div>
            <div
              className="lesson-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          {/* <Aside frontmatter={frontmatter} /> */}
        </main>
        <EndNav next={next} prev={prev} />
        <EditOnGithub path={pagePath} prod="true" />
        <Comments path={pagePath} />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query($filePath: String!) {
    markdownRemark(fileAbsolutePath: { regex: $filePath }) {
      html
      id
    }
  }
`
/*
{
  Temporarily removing the media object until we can make it optional.
      html
      frontmatter {
        path
        title
        objective
        emoji
        next
        prev
        media {
          url
          title
          type
          note
          source
        }
      }
    }
*/
